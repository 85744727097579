/**
 *
 * @param person
 */
function getFullName(person) {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const anonymous =
		characters.charAt(Math.floor(Math.random() * characters.length)) +
		'**** ' +
		characters.charAt(Math.floor(Math.random() * characters.length)) +
		'****';

	const name =
		person?.firstName || person?.lastName
			? person.firstName && person.lastName
				? person.firstName + ' ' + person.lastName
				: person.firstName || person.lastName
			: person?.name;
	return name || anonymous;
}

/**
 *
 * @param html
 */
function htmlToText(html) {
	if (!html) return;
	return html.replace(/<[^>]+>/g, '');
}

export { getFullName, htmlToText };
